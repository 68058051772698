<template>
  <div class="bodyPage">

    <div class="ov_titile">
      <el-input style="width:200px;" v-model="queryData.order_sn" @change="queryOrderSn" placeholder="订单号查询"></el-input> &nbsp;
      <el-button @click="add" type="primary">
        添加审批单据
      </el-button>
    </div>

    <div class="ov_center">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="id" label="ID" width="180" align="center">
        </el-table-column>
        <el-table-column prop="order_sn" label="订单号" width="280" align="center">
          <template slot-scope="scope">
            <span v-html="glOrderSn(scope.row.order_sn)"></span>
          </template>
        </el-table-column>
        <el-table-column prop="order_price" label="价格" width="180" align="center">
        </el-table-column>
        <el-table-column prop="remarks" label="备注" align="center">
        </el-table-column>
        <el-table-column prop="receopt_sn" label="回单号" align="center">
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button @click="edit(scope.row)" type="warning" size="mini">
              编辑
            </el-button>
            <el-button @click="look(scope.row)" type="primary" size="mini">
              查看
            </el-button>
            <el-button @click="delClick(scope.row)" type="danger" size="mini">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="ov_pagefoot">
      <!-- 分页 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="queryData.page" :page-sizes="[10, 20, 40, 80, 100]" :page-size="queryData.size" layout="sizes, prev, pager, next" :total="queryData.total">
      </el-pagination>

    </div>

    <el-drawer title="添加审批单据" :visible.sync="editOv.drawer" :direction="editOv.direction" size="50%">
      <add ref="addComponent" @closeAdd="closeAdd" @getOrderVerificationData="getOrderVerificationData"></add>
    </el-drawer>

    <el-drawer title="审批单据数据列表" :visible.sync="ovlist.drawer" :direction="ovlist.direction" size="70%">
      <list ref="listComponent"></list>
    </el-drawer>
  </div>
</template>

<script>
import add from "./add";
import list from "./list";

export default {
  components: {
    add,
    list,
  },
  data() {
    return {
      // [编辑|添加]抽屉
      editOv: {
        drawer: false,
        direction: "rtl",
      },
      //[列表]审批列表的抽屉
      ovlist: {
        drawer: false,
        direction: "rtl",
      },

      // 查询条件
      queryData: {
        size: 10,
        page: 1,
        total: 21,
        order_sn: "",
      },
      tableData: [],
    };
  },
  created() {
    this.getOrderVerificationData(); // 获取订单核销数据
  },
  methods: {
    /**
     * 获取订单验证数据
     * 无参数
     * 无明确返回值，但会将获取的数据赋值给tableData属性
     */
    getOrderVerificationData() {
      // 使用axios发起GET请求，请求路径为/a1/order/ov/list，携带的参数为queryData对象的内容
      this.$axios.get("/a1/order/ov/list", { params: this.queryData }).then((res) => {
        // 请求成功后，将返回数据的data.data.data部分赋值给tableData
        this.tableData = res.data.data.data;
        this.queryData.total = res.data.data.total;
      });
    },
    // 添加
    add() {
      this.editOv.drawer = true;
      this.$nextTick(() => {
        this.$refs.addComponent.loadData({});
      });
    },
    // 编辑
    edit(row) {
      this.editOv.drawer = true;
      this.$nextTick(() => {
        this.$refs.addComponent.loadData(row);
      });
    },
    // 查看详情
    look(row) {
      this.ovlist.drawer = true;
      this.$nextTick(() => {
        this.$refs.listComponent.loadData(row);
      });
    },
    // 删除
    delClick(row) {
      console.log(row);
    },
    //页数
    handleSizeChange(val) {
      this.queryData.size = val;
      this.getOrderVerificationData();
    },
    //变更
    handleCurrentChange(val) {
      this.queryData.page = val;
      this.getOrderVerificationData();
    },
    // 查询订单号
    queryOrderSn() {
      this.getOrderVerificationData();
    },
    //关闭添加
    closeAdd() {
      this.editOv.drawer = false;
    },
    //字符显示
    glOrderSn(s){
      //字符串以空格切割成数组
      let arr = s.split("\n");
      //循环数组拼接成一个新字符串
      let newStr = arr.join("<br/>");
      //返回一个html
      return newStr;
    }
  },
};
</script>

<style lang="scss">
 .bodyPage {
  display: flex;
  flex-direction: column;
  height: 90%; /* 使用100%视口高度 */
  overflow: hidden; /* 隐藏溢出的内容 */
}

.ov_titile {
  flex-shrink: 0; /* 不允许缩小 */
  min-height: 60px; /* 使用最小高度 */
  border-bottom: 1px solid #ccc;
  padding: 16px;
  margin-bottom: 0; /* 可能需要调整margin */
}

.ov_center {
  flex-grow: 1; /* 允许增长以填充剩余空间 */
  overflow-y: auto; /* 允许垂直滚动 */
  border: 1px solid #ccc;
}

.ov_pagefoot {
  flex-shrink: 0; /* 不允许缩小 */
  min-height: 50px; /* 使用最小高度 */
  background-color: #f4f4f4;
}
</style>
