<template>
  <div class="ovForm">
    <!-- 审核详情信息 -->
    <div>
      <table class="tableOrderOV">
        <tr>
          <td style="width: 100px">订单号</td>
          <td><span v-html="glOrderSn(ovInfo.order_sn)"></span></td>
          <td style="width: 100px">审核状态</td>
          <td>
            <el-tag v-if="ovInfo.operate_type === 0" type="success">全部未审</el-tag>
            <el-tag v-else-if="ovInfo.operate_type === 1" type="danger">全部通过</el-tag>
            <el-tag v-else-if="ovInfo.operate_type === 2" type="warning">全部驳回</el-tag>
            <el-tag v-else-if="ovInfo.operate_type === 3" type="warning">审核中</el-tag>
            <el-tag v-else-if="ovInfo.operate_type === 4" type="warning">部分通过，部分驳回</el-tag>
          </td>
          <td style="width: 100px">备注</td>
          <td>{{ovInfo.remarks}}</td>
        </tr>
        <tr>
          <td style="width: 100px">订单总价</td>
          <td>{{ovInfo.order_price}}</td>
          <td style="width: 100px">通过价格</td>
          <td>{{ovInfo.order_pass_price}}</td>
          <td style="width: 100px">回写单号</td>
          <td>
            <div>
              <p style="color:red">状态：{{get_has_syn(ovInfo.has_syn)}}</p>
              {{ovInfo.receopt_sn}}
            </div>
            <el-button type="primary" size="mini" @click="receiptClick">同步</el-button>
          </td>
        </tr>
        <tr>
          <td style="width: 100px">待审核数</td>
          <td>{{ovInfo.s0}}</td>
          <td style="width: 100px">通过数</td>
          <td>{{ovInfo.s1}}</td>
          <td style="width: 100px">驳回数</td>
          <td>
            {{ovInfo.s2}}
          </td>
        </tr>
      </table>
    </div>

    <div>
      <!-- 按钮：全部通过，剩余通过，剩余驳回,全部驳回 -->
      <el-button type="primary" size="mini" @click="allPassClick">全部通过</el-button>
      <el-button type="primary" size="mini" @click="remainPassClick">剩余通过</el-button>
      <el-button type="primary" size="mini" @click="remainRejectClick">剩余驳回</el-button>
      <el-button type="primary" size="mini" @click="rejectAllClick">全部驳回</el-button>
    </div>

    <el-table :data="tableData" border height="400" style="width: 100%; margin-top: 20px">
      <!-- <el-table-column prop="id" label="ID" width="80">
      </el-table-column> -->
      <el-table-column prop="order_sn" label="订单号" width="150">
        <template slot-scope="scope">
          <div :style="{ color: orderBackgroundColor(scope.row.order_sn) }">
            {{ scope.row.order_sn }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="sku" label="商品SKU" width="120">
      </el-table-column>
      <el-table-column prop="total" label="总价">
      </el-table-column>
      <el-table-column prop="num" label="商品数量" width="90">
      </el-table-column>
      <el-table-column prop="price" label="单价">
      </el-table-column>

      <el-table-column label="海鼎实际配货数">
        <template slot-scope="scope">
          <el-tag type="danger">{{scope.row.hd_qty}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="海鼎计划配货数">
        <template slot-scope="scope">
          <el-tag type="danger">{{scope.row.hd_allocqty}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="海鼎原出货金额">
        <template slot-scope="scope">
          <el-tag type="danger">{{scope.row.hd_srctotal}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="海鼎实际金额">
        <template slot-scope="scope">
          <el-tag type="danger">{{scope.row.hd_total}}</el-tag>
        </template>
      </el-table-column>

      <el-table-column prop="total" label="状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.operate_type == 0" type="info">待审</el-tag>
          <el-tag v-if="scope.row.operate_type == 1" type="success">通过</el-tag>
          <el-tag v-if="scope.row.operate_type == 2" type="warning">驳回</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="mini" type="success" @click="okWarningClick(scope.row,1)">通过</el-button>
          <el-button size="mini" type="warning" @click="okWarningClick(scope.row,2)">驳回</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      ovInfo: {},
      // 定义颜色映射
      colorMap: {
        1: "green",
        2: "blue",
        3: "red",
        4: "gray",
        5: "orange",
        6: "purple",
        7: "pink",
        8: "cyan",
        9: "lime",
        0: "brown",
      },
    };
  },
  methods: {
    // 加载数据
    loadData(row) {
      this.ovInfo = row;
      this.$axios.get("/a1/order/ov/goods/" + row.id).then((res) => {
        // 请求成功后，将返回数据的data.data.data部分赋值给tableData
        this.tableData = res.data.data;
      });
    },
    // 重新刷新订单审核详情信息
    relaodOVinfo() {
      this.$axios.get("/a1/order/ov/row/" + this.ovInfo.id).then((res) => {
        this.ovInfo = res.data.data;
      });
    },
    // 通过|驳回
    okWarningClick(row, s) {
      if (this.ovInfo.id) {
        this.$axios.put("/a1/order/ov/checkOvGoods/" + row.id + "/" + s).then((res) => {
          //根据res状态弹出提示框
          if (res.data.error == 0) {
            this.relaodOVinfo(this.ovInfo.id);
            this.loadData(this.ovInfo);
            this.$message({
              message: "审批-操作成功",
              type: "success",
            });
          } else {
            this.$message.error(res.data.msg);
          }
        });
      } else {
        this.$message.error("无数据提交");
      }
    },
    // 全部通过
    allPassClick() {
      if (this.ovInfo.id) {
        this.$axios.put("/a1/order/ov/allPass/" + this.ovInfo.id).then((res) => {
          //根据res状态弹出提示框
          if (res.data.error == 0) {
            this.loadData(this.ovInfo);
            this.$message({
              message: "全部通过-操作成功",
              type: "success",
            });
          } else {
            this.$message.error(res.data.msg);
          }
        });
      } else {
        this.$message.error("无数据提交");
      }
    },
    // 剩余通过
    remainPassClick() {
      if (this.ovInfo.id) {
        this.$axios.put("/a1/order/ov/remainPass/" + this.ovInfo.id).then((res) => {
          //根据res状态弹出提示框
          if (res.data.error == 0) {
            this.loadData(this.ovInfo);
            this.$message({
              message: "剩余通过-操作成功",
              type: "success",
            });
          } else {
            this.$message.error(res.data.msg);
          }
        });
      } else {
        this.$message.error("无数据提交");
      }
    },
    // 剩余驳回
    remainRejectClick() {
      if (this.ovInfo.id) {
        this.$axios.put("/a1/order/ov/remainReject/" + this.ovInfo.id).then((res) => {
          //根据res状态弹出提示框
          if (res.data.error == 0) {
            this.loadData(this.ovInfo);
            this.$message({
              message: "剩余驳回-操作成功",
              type: "success",
            });
          } else {
            this.$message.error(res.data.msg);
          }
        });
      } else {
        this.$message.error("无数据提交");
      }
    },
    //全部驳回
    rejectAllClick() {
      if (this.ovInfo.id) {
        this.$axios.put("/a1/order/ov/rejectAll/" + this.ovInfo.id).then((res) => {
          //根据res状态弹出提示框
          if (res.data.error == 0) {
            this.loadData(this.ovInfo);
            this.$message({
              message: "全部驳回-操作成功",
              type: "success",
            });
          } else {
            this.$message.error(res.data.msg);
          }
        });
      } else {
        this.$message.error("无数据提交");
      }
    },
    // 回写单号同步
    receiptClick() {
      if (this.ovInfo.id) {
        this.$axios.put("/a1/order/ov/check2num/" + this.ovInfo.id).then((res) => {
          //根据res状态弹出提示框
          if (res.data.error == 0) {
            this.loadData(this.ovInfo);
            this.$message({
              message: "同步成功",
              type: "success",
            });
            this.ovInfo.has_syn = 1
          } else {
            this.$message.error(res.data.msg);
          }
        });
      } else {
        this.$message.error("无数据提交");
      }
    },
    // 计算属性，根据订单号返回不同的背景颜色
    orderBackgroundColor(orderSn) {
      // 使用哈希函数将订单号转换为一个索引
      const index = parseInt(orderSn) % Object.keys(this.colorMap).length;
      // 根据索引返回颜色
      return this.colorMap[index] || "black"; // 如果没有匹配的颜色，则使用黑色作为默认颜色
    },
    //订单号字符显示
    glOrderSn(s) {
      let ns = "";
      // 确保 s 不是 undefined
      if (typeof s !== "undefined") {
        // 字符串以空格切割成数组
        let arr = s.split("\n");
        //循环数组拼接成一个新字符串
        for (let index = 0; index < arr.length; index++) {
          const v = arr[index];
          const c = this.orderBackgroundColor(v);
          ns += '<p style="color:' + c + '">' + v + "</p>";
        }
      }
      return ns;
    },
    get_has_syn(s){
      if (s == 1) {
        return "已同步"
      } else {
        return "未同步"
      }
    }
  },
};
</script>

<style scoped>
.ovForm {
  padding: 20px;
}
.tableOrderOV {
  width: 100%;
  border-collapse: collapse; /* 折叠边框，使边框合并 */
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.tableOrderOV thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
}

.tableOrderOV th,
.tableOrderOV td {
  padding: 12px 15px;
  border: 1px solid #dddddd;
}

.tableOrderOV tbody tr {
  border-bottom: 1px solid #dddddd;
}

.tableOrderOV tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.tableOrderOV tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}
</style>