<template>
  <div class="ovForm">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="订单单号" prop="order_sn">
        <el-input type="textarea" rows="5" v-model="ruleForm.order_sn" class="orderNum"></el-input>
        <div class="orderNumDiv">
          多个例子：<br/>
          1534416367000001w<br/>
          1534416367000002w
        </div>
      </el-form-item>
      <el-form-item label="备注" prop="region">
        <el-input type="textarea" v-model="ruleForm.remarks"></el-input>
      </el-form-item>
      <el-form-item label="回单号" prop="region">
        <el-input v-model="ruleForm.receopt_sn"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm">提交</el-button>
        <el-button @click="closeDIV">取消</el-button>
        <el-input type="hidden" v-model="ruleForm.id"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 表单数据
      ruleForm: {
        id: 0,
        order_sn: "",
        remarks: "",
        receopt_sn: "",
        subType: "add",
      },
      rules: {
        order_sn: [
          { required: true, message: "请输入订单号", trigger: "blur" },
          // { min: 5, max: 120, message: "长度为5-120位", trigger: "blur" },
        ],
      },
    };
  },
  // 挂载后执行
  mounted() {
    // this.loadData();
  },
  methods: {
    // 加载数据
    loadData(row) {
      this.ruleForm.id = row.id;
      this.ruleForm.order_sn = row.order_sn;
      this.ruleForm.remarks = row.remarks;
      this.ruleForm.receopt_sn = row.receopt_sn;
      if (this.ruleForm.order_sn != "") {
        this.ruleForm.subType = "update";
      } else {
        this.ruleForm.subType = "add";
      }
    },
    // 提交订单
    submitForm() {
      this.$axios
        .post("/a1/order/ov/add", this.ruleForm)
        .then((res) => {
          console.log(res);
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.ruleForm = {
            order_sn: "",
            remarks: "",
            receopt_sn: "",
          };
          this.closeDIV()
          this.$emit("getOrderVerificationData");
        })
        .catch((error) => {
          // 错误处理逻辑
          if (error.response) {
            const responseData = error.response.data;
            console.log(responseData);
            this.$message({
              type: "error",
              message: responseData.message,
            });
          } else {
            this.$message({
              type: "error",
              message: "网络问题，无法获取响应",
            });
          }
        });
    },
    // 关闭
    closeDIV(){
      //初始化数据
      this.ruleForm = {
        id: 0,
        order_sn: "",
        remarks: "",
        receopt_sn: "",
        subType: "add",
      }
      this.$emit('closeAdd')
    }
  },
};
</script>

<style scoped lang="scss">
.ovForm {
  padding: 20px;
  
  .orderNumDiv{
    color: #7e7575;
    font-size: 12px;
  }
  .orderNum{
    width: 50%;
  }

}
</style>